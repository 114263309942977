






































































































import { Component } from 'vue-property-decorator';
import StudentsToolbar from './StudentsToolbar.vue';

  @Component({
    components: {
      StudentsToolbar
    }
  })
export default class StudentsBottomToolbar extends StudentsToolbar {
}
